import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getStorage } from "firebase/storage";
import 'bootstrap-icons/font/bootstrap-icons.css';

const firebaseApp = initializeApp({
  apiKey: "AIzaSyBeygKxLf20cBS2J5-il8SWuzH1fjYlwOE",
  authDomain: "avian-destiny-253922.firebaseapp.com",
  projectId: "avian-destiny-253922",
  storageBucket: "avian-destiny-253922.appspot.com",
  messagingSenderId: "656048792899",
  appId: "1:656048792899:web:2ade16b1d0e8a4a683fbf6",
});

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6Lc7KF8iAAAAADZRhTboMiU7WO7akGfRtD_09SQO'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

const firebaseStorage = getStorage(firebaseApp);
export { firebaseApp, firebaseStorage }

ReactDOM.render(<App />, document.getElementById('root'));
