import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./AppRouter";
// import Home from "./Home"
// import Cases from "./Cases"
import "./App.scss"
import { GlobalContextWrapper } from './context/GlobalState';

const App = () => {
  return (
    <>
    {/* <GlobalContextWrapper> */}
      <div 
      // className="App"
      >
        <BrowserRouter>
          {/* <ChakraProvider> */}
            <AppRouter />
          {/* </ChakraProvider> */}
        </BrowserRouter>
      </div>
    {/* </GlobalContextWrapper> */}
    </>
  );
};

export default App;
