import './glossary.scss'

const GlossaryContent = () => {
  return (
    <div className="glossary-styles">
        <body class="c7 doc-content"         style={{minWidth:'100%', marginLeft:'auto', marginRight:'auto'}}
>
    <p class="c18"><span class="c15">Understanding Public Records Act requests for Police Records &ndash; Terms</span>
    </p>
    <p class="c25"><span class="c15">Background Terms</span></p>
    <p class="c0"><span class="c2">Public Records Act</span><span class="c4">&nbsp;(PRA): The Public Records Act is a
            series of laws designed to guarantee that the public has access to public records of governmental bodies in
            California, </span><span class="c4 c11 c24">unless exempted by law. </span><span class="c4">The PRA was
            enacted in 1968 to: (1) safeguard the accountability of government to the public; (2) promote maximum
            disclosure regarding governmental operations; and (3) explicitly acknowledge the principle that secrecy is
            incompatible with a democratic system of </span><span class="c4 c20">government of the people, by the people
            and for the people</span><span class="c3">. The legislation was modeled on the federal Freedom of
            Information Act (FOIA). </span></p>
    <p class="c0"><span class="c2">Access to Records before SB 1421</span><span class="c4">: Before 2019, records
            relating to officer use-of-force incidents and </span><span class="c4 c14">misconduct</span><span
            class="c4">&nbsp;were only available via a court order, i.e., a </span><span
            class="c4 c16 c20">Pitchess</span><span class="c4">&nbsp;motion and private review by a judge or arbitrator.
            Records released under </span><span class="c4 c20">Pitchess</span><span class="c4">&nbsp;motions were
            generally sealed by a protective order limiting disclosure of the content. SB 1421, effective January 1,
            2019, dramatically altered the ability of the public (and the press) to obtain previously highly
            confidential police misconduct </span><span class="c4 c16">records</span><span class="c3">.</span></p>
    <p class="c0"><span class="c2 c16 c20">Pitchess</span><span class="c2">&nbsp;Motions:</span><span
            class="c4">&nbsp;In 1974, the California Supreme Court decision in</span><span class="c22 c4">&nbsp;Pitchess
            v. Superior Court</span><span class="c4">&nbsp;held that a criminal defendant could discover information
            regarding a peace officer&rsquo;s personnel file upon an adequate showing. In response, the California
            Legislature enacted a statutory rubric under which a party to litigation must file a written motion and
            establish </span><span class="c4 c20">good cause</span><span class="c4">&nbsp;for the discovery of otherwise
            confidential police officer </span><span class="c4 c14">misconduct</span><span class="c4">&nbsp;records
            (otherwise known as a Pitchess motion). If a court finds good cause, it will conduct an </span><span
            class="c4 c20">in-camera </span><span class="c3">(meaning in private) inspection of the requested records
            and disclose any relevant information to the requesting party.</span></p>
    <p class="c0"><span class="c2">Intent of SB 1421: &nbsp;</span><span class="c4">According to the proponents of the
            new legislation, SB 1421 was intended to </span><span class="c4 c20">lift the veil of secrecy</span><span
            class="c4">&nbsp;and provide transparency and accountability with regard to law enforcement. Regarding its
            purpose, the bill states, in part, that </span><span class="c4 c20">the public has a right to know all about
            serious police </span><span class="c4 c20 c14">misconduct</span><span class="c4 c20">, as well as about
            officer-involved shootings and other serious uses of force. Concealing crucial public safety matters such as
            officer violations of civilians&rsquo; rights, or inquiries into deadly use of force incidents, undercuts
            the public&rsquo;s faith in the legitimacy of law enforcement, makes it harder for tens of thousands of
            hardworking peace officers to do their jobs, and endangers public safety</span><span class="c3">.</span></p>
    <p class="c0"><span class="c2">Effect of SB 1421</span><span class="c4">: This legislation expanded public access to
            records relating to officer use-of-force incidents and </span><span class="c4 c14">sustained</span><span
            class="c4">&nbsp;findings of </span><span class="c4 c14">misconduct</span><span class="c4">&nbsp;through the
            California </span><span class="c4 c16">Public Records Act</span><span class="c3">. </span></p>
    <p class="c0"><span class="c2">Effect of SB 16</span><span class="c4">: </span><span class="c4 c11 c17">This
            legislation created new procedural and substantive rules for the </span><span
            class="c4 c5">retention</span><span class="c4 c11 c17">&nbsp;and release of </span><span
            class="c4 c5">records</span><span class="c4 c11 c17">&nbsp;currently mandated for disclosure under Senate
            Bill 1421. It also added four new categories of </span><span class="c4 c13">sustained</span><span
            class="c4 c11 c17">&nbsp;findings of police </span><span class="c4 c13">misconduct</span><span
            class="c3 c11">&nbsp;that trigger disclosure.</span></p>
    <p class="c0 c21"><span class="c3 c11"></span></p>
    <p class="c25"><span class="c15">General Terms</span></p>
    <p class="c0 c11"><span class="c2 c6">Bias-Based Policing Records</span><span class="c4 c6">: SB 16 dictates
        </span><span class="c4 c10">records</span><span class="c4 c6">&nbsp;be released relating to </span><span
            class="c4">a </span><span class="c4 c14">sustained</span><span class="c4">&nbsp;finding that an officer
            engaged</span><span class="c4 c17">&nbsp;in conduct including, but not limited to, verbal statements,
            writings, online posts, recordings, and gestures involving prejudice or discrimination against a person in a
        </span><span class="c4 c5">protected class</span><span class="c4 c17">&nbsp;[</span><span
            class="c4 c13">misconduct</span><span class="c3">].</span></p>
    <p class="c0 c11"><span class="c2 c16">Dishonesty</span><span class="c3">&nbsp;means an officer&rsquo;s
            untruthfulness was directly related to the reporting, investigation, or prosecution of a crime, or directly
            related to the reporting of or investigation of misconduct by another officer, including, but not limited
            to, any sustained finding of perjury, false statements, filing false reports, destruction, falsifying, or
            concealing of evidence.</span></p>
    <p class="c0 c11"><span class="c2">Dishonesty Records:</span><span class="c4">&nbsp;</span><span class="c4 c6">SB
            1421 dictates </span><span class="c4 c10">records</span><span class="c4 c6">&nbsp;be released relating to
        </span><span class="c4">a sustained finding that an officer was </span><span
            class="c4 c16">dishonest</span><span class="c4">&nbsp;[</span><span class="c4 c14">misconduct</span><span
            class="c3">].</span></p>
    <p class="c0"><span class="c2 c16">Excessive/Unreasonable Force</span><span class="c2">: </span><span class="c3">A
            level of force utilized by an officer that was not objectively reasonable. The Fourth Amendment of the
            United States Constitution, as well as police department policies, protect citizens from excessive force by
            police.</span></p>
    <p class="c0 c11"><span class="c2">Excessive/Unreasonable Force Records:</span><span class="c4">&nbsp;</span><span
            class="c4 c6">&nbsp;SB 16 dictates </span><span class="c4 c10">records</span><span class="c4 c6">&nbsp;be
            released relating to </span><span class="c4">a </span><span class="c4 c16">sustained</span><span
            class="c4">&nbsp;finding that an officer engaged in </span><span class="c4 c5">excessive/unreasonable force
            [</span><span class="c4 c13">misconduct</span><span class="c4 c17">].</span></p>
    <p class="c0 c11"><span class="c2 c6">Failure to Intervene Records</span><span class="c4 c6">: SB 16 dictates
        </span><span class="c4 c10">records</span><span class="c4 c6">&nbsp;be released relating to </span><span
            class="c4">a </span><span class="c4 c14">sustained</span><span class="c4">&nbsp;finding that an officer
        </span><span class="c4 c17">failed to intervene [</span><span class="c4 c13">misconduct</span><span
            class="c4 c17">] against another officer using force that is clearly </span><span
            class="c4 c5">unreasonable/excessive</span><span class="c3">.</span></p>
    <p class="c8"><span class="c2 c10">Firearm</span><span class="c2 c6">:</span><span class="c3">&nbsp;Also called a
            weapon, gun, handgun, long gun, pistol, revolver, etc. An assembly of a barrel and action from which a
            projectile is discharged by means of a rapidly burning propellant.</span></p>
    <p class="c8"><span class="c2 c10">Great Bodily Injury</span><span class="c2 c6">&nbsp;(GBI):</span><span
            class="c4 c6">&nbsp;According to statute and case law, GBI </span><span class="c3">means significant or
            substantial physical injury, not minor or moderate injury. GBI does not require permanent, prolonged, or
            protracted bodily damage. The aggregation of smaller injuries, such as multiple bruises over various body
            parts, along with swelling, discoloration, and pain that lasts until the day after the incident can be
            sufficient to show GBI. (See Cal. Penal Code, &sect; 12022.7(f); CALCRIM No. 810.; People v. Cross, 45
            Cal.4th 58, 64 (2008); People v. Jaramillo, 98 Cal.App.3d 830, 836 (1979).)</span></p>
    <p class="c8"><span class="c2 c16">Member of the Public</span><span class="c4">&nbsp;means any person not employed
            by the officer&rsquo;s employing agency and includes any participant in a cadet, explorer, or other youth
            program affiliated with the agency.</span></p>
    <p class="c0 c11"><span class="c2 c13">Misconduct</span><span class="c2 c11 c17">:</span><span
            class="c4 c11 c17">&nbsp;Under SB 1421 and SB 16, there are seven categories of </span><span
            class="c4 c5">sustained</span><span class="c4 c11 c17">&nbsp;findings of police misconduct that trigger
            disclosure of related </span><span class="c4 c5">records</span><span class="c4 c11 c17">: 1) </span><span
            class="c4 c5">Dishonesty</span><span class="c4 c11 c17">&nbsp;2) </span><span class="c4 c5">Sexual
            Assault</span><span class="c4 c11 c17">&nbsp;3) </span><span class="c4 c5">Excessive Force</span><span
            class="c4 c11 c17">, 4) </span><span class="c4 c5">Failure to Intervene</span><span
            class="c4 c11 c17">&nbsp;5) </span><span class="c4 c5">Prejudice/Discrimination</span><span
            class="c4 c11 c17">&nbsp;against a person in a protected class 6) </span><span class="c4 c5">Unlawful
            Arrest</span><span class="c4 c11 c17">&nbsp;7) </span><span class="c4 c5">Unlawful Search</span><span
            class="c3 c11">.</span></p>
    <p class="c0 c11"><span class="c2">Multiple Officers:</span><span class="c4">&nbsp;Records of an incident may
            pertain to the actions of one officer or several. If an investigation or incident involves allegations of
            wrongdoing against multiple officers, the Department must </span><span class="c4 c16">redact</span><span
            class="c3">&nbsp;alleged misconduct that is not independently disclosable, while disclosing information that
            is pertinent to the investigation of the disclosable conduct.</span></p>
    <p class="c0 c11"><span class="c2 c5">Protected Class</span><span class="c2 c17">:</span><span
            class="c4 c17">&nbsp;This term refers to </span><span class="c4 c11 c26">groups of people who are legally
            protected from discrimination due to a shared characteristic. SB 16 defines those characteristics as
        </span><span class="c3">race, religious creed, color, national origin, ancestry, physical disability, mental
            disability, medical condition, genetic information, marital status, sex, gender, gender identity, gender
            expression, age, sexual orientation, or military and veteran status. </span></p>
    <p class="c0 c11"><span class="c2 c16">Redactions</span><span class="c2">:</span><span class="c3">&nbsp;Agencies
            must redact specified information before records are released. This includes, but is not limited to: </span>
    </p>
    <p class="c9"><span class="c19 c4">1) to remove personal data or information, such as a home address, telephone
            number, or identities of family members, etc; </span></p>
    <p class="c9"><span class="c4 c19">2) to preserve the anonymity of complainants, witnesses, whistleblowers, and
            victims;</span></p>
    <p class="c9"><span class="c19 c4">3) to protect confidential medical, financial, or other information of which
            disclosure is specifically prohibited by federal law or would cause an unwarranted invasion of personal
            privacy;</span></p>
    <p class="c9"><span class="c19 c4">4) where there is a specific, articulable, and particularized reason to believe
            that disclosure of the record would pose a significant danger to the physical safety of the police or
            another person; and </span></p>
    <p class="c0 c11 c27"><span class="c4 c23">5) </span><span class="c4 c6">personal identifying information, where, on
            the facts of the particular case, the public interest served by </span><span
            class="c4 c6 c22">not</span><span class="c1">&nbsp;disclosing the information clearly outweighs the public
            interest served by disclosure of the information.</span></p>
    <p class="c0 c11"><span class="c2 c16">Releasable Records</span><span class="c2">:</span><span class="c4">&nbsp;SB
            1421 defines releasable records as including: 1) all investigative reports; 2) photographic, audio, and
            video evidence; 3) transcripts or recordings of interviews; 4) autopsy reports; 5) all materials presented
            for review to the district attorney or other prosecutorial entity to determine whether criminal charges
            should be filed or to the authorized administrative entity that determines whether the officer&rsquo;s
            actions were consistent with law/policy for purposes of discipline or administrative action, or what
            discipline to impose or corrective action to take; 6) documents setting forth findings or recommended
            findings; 7) copies of disciplinary records relating to the incident, including any letters of intent to
            impose discipline, any documents reflecting modifications of discipline due to the Skelly
            &nbsp;(administrative) or grievance process, and letters indicating final imposition of discipline or other
            documentation reflecting the implementation of corrective action. All records must be </span><span
            class="c4 c16">redacted</span><span class="c3">&nbsp;before release.</span></p>
    <p class="c0 c11"><span class="c2 c16">Retention</span><span class="c2">&nbsp;of Records:</span><span
            class="c4">&nbsp;SB 16 dictates that </span><span class="c4 c17">Records with no sustained finding of police
            misconduct must be retained for at least 5 years and records related to </span><span
            class="c4 c13">sustained</span><span class="c4 c17">&nbsp;police </span><span
            class="c4 c13">misconduct</span><span class="c3">&nbsp;must be retained for a minimum of 15 years.</span>
    </p>
    <p class="c8"><span class="c2 c16">Sexual Assault</span><span class="c3">&nbsp;means the commission or attempted
            initiation of a sexual act with a member of the public by means of force, threat, coercion, extortion, offer
            of leniency or other official favor, or under the color of authority. For purposes of this definition, the
            propositioning for or commission of any sexual act while on duty is considered a sexual assault.</span></p>
    <p class="c8"><span class="c2 c6">Sexual Assault Records:</span><span class="c4 c6">&nbsp;SB 1421 dictates
        </span><span class="c4 c10">records</span><span class="c4 c6">&nbsp;be released relating to a sustained finding
            that a police officer engaged in </span><span class="c4 c10">sexual assault</span><span
            class="c4 c6">&nbsp;[</span><span class="c4 c14 c23">misconduct</span><span class="c4 c6">] involving
        </span><span class="c4 c10">a member of the public</span><span class="c1">.</span></p>
    <p class="c0 c11"><span class="c2 c14">Sustained</span><span class="c3">&nbsp;means a final determination by
            employing agency, the civil service commission, or arbitrator following an investigation and opportunity for
            an administrative appeal pursuant to Sections 3304 and 3304.5 of the Government Code, that the actions of
            the police officer violated law or Department policy. </span></p>
    <p class="c0 c11"><span class="c2 c16">Unlawful Arrest</span><span class="c2">: </span><span class="c4">An arrest
            made without probable cause or an arrest warrant is unlawful. </span></p>
    <p class="c0 c11"><span class="c2">Unlawful Arrest Records:</span><span class="c4">&nbsp;</span><span
            class="c4 c6">SB 16 dictates </span><span class="c4 c10">records</span><span class="c4 c6">&nbsp;be released
            relating </span><span class="c4">a sustained finding that an officer </span><span class="c4 c17">conducted
            an </span><span class="c4 c5">unlawful arrest</span><span class="c4 c17">&nbsp;[</span><span
            class="c4 c13">misconduct</span><span class="c3">].</span></p>
    <p class="c0 c11"><span class="c2 c16">Unlawful Search</span><span class="c2">: </span><span class="c4">An unlawful
            search violates the protections proved by the </span><span class="c4 c12">Fourth Amendment of the United
            States Constitution.</span></p>
    <p class="c0 c11"><span class="c2">Unlawful Search Records:</span><span class="c4">&nbsp;</span><span
            class="c4 c6">SB 16 dictates </span><span class="c4 c10">records</span><span class="c4 c6">&nbsp;be released
            relating </span><span class="c4">a sustained finding that an officer </span><span class="c4 c17">conducted
            an </span><span class="c4 c5">unlawful search</span><span class="c4 c17">&nbsp;[</span><span
            class="c4 c13">misconduct</span><span class="c3">].</span></p>
    <p class="c8"><span class="c2 c6">Use of Firearms Records:</span><span class="c4 c6">&nbsp;SB 1421 dictates that
        </span><span class="c4 c10">records</span><span class="c4 c6">&nbsp;be released relating to an investigation or
            findings of an incident in which a police officer discharged a </span><span
            class="c4 c10">firearm</span><span class="c4 c6">&nbsp;at a person.</span></p>
    <p class="c8"><span class="c2 c6">Use of Force Records:</span><span class="c4 c6">&nbsp;SB 1421 dictates that
        </span><span class="c4 c10">records</span><span class="c4 c6">&nbsp;be released relating to the investigation or
            findings of an incident in which the use of force by a police officer against a person resulted in death, or
            in </span><span class="c4 c10">great bodily injury</span><span class="c1">.</span></p>
    <p class="c0 c21 c11"><span class="c3"></span></p>
    <p class="c0 c21"><span class="c3"></span></p>
</body>


    </div>
  );
};

const Glossary = () => {
  function newTab(url) {
    window.open(url, "_blank");
  }
  return (
    <>
      {/* <div style={{ height: "1px" }} /> */}
      <div
        style={{
          fontFamily: "Lato",
          borderLeft: "solid black 5px",
          borderRight: "solid black 5px",
        }}
      >
        {/* <Hero /> */}

        {/* <div style={{ height: "4px" }} />  */}

        <section
          className="p-5 pt-4"
          style={{
            // fontFamily: "Lato",
            border: "solid black 1px",
            borderRadius: "2px",
            color: "white",
            backgroundColor: "#1B1F21", // Shark
        //     boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.4)",
            position: "relative",
          }}
        >
          <h1
            className="page-header mb-5 text-center mt-4"
            style={{ fontWeight: "700", color:'white' }}
          >
            Glossary
          </h1>

          {/* <p className="text-center mb-5">
            Find the answers for the most frequently asked questions below
          </p> */}

          <GlossaryContent />
        </section>
        <br />
      </div>
    </>
  );
};

export default Glossary;
