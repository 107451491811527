import React, { useEffect, useRef, useState } from "react";
import {
  Routes,
  Route,
  Link,
  Outlet,
  useLocation,
  useNavigate,
  Navigate
} from "react-router-dom";

import { getFiles } from "./FileFetch";
import {
  InstantSearch,
  Hits,
  // HierarchicalMenu,
  RefinementList,
  SearchBox,
  // SortBy,
  Stats,
  Pagination,
  Panel,
  ClearRefinements,
  // RatingMenu,
  // RangeInput,
  Highlight,
  Configure,
  connectStateResults,
} from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";
import withURLSync from "./URLSync";
import qs from "qs";

// Routes
import Home from "./Home";
import Cases from "./Cases";
import ViewCase from "./ViewCase";
import Glossary from "./Glossary";

// Logos
// import sj_logo from "./ShowPublishedImage.png"
import sj_logo from "./assets/SJ Logo.png";
import SJ_IPA_logo from "./assets/SJ IPA Logo.png";

import ReactTooltip from "react-tooltip";

import NotFoundPage from './NotFoundPage.jsx';

let blank = {
  query: "",
  configure: { hitsPerPage: 16 },
  page: 1,
};

const DEBOUNCE_TIME = 400;
const searchClient = algoliasearch(
  "Y4H4S754AV",
  "3a6f2fcaf2b5013274b1aa887e7785e4"
);

const createURL = (state) => `?${qs.stringify(state)}`;

const searchStateToUrl = (location, searchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : "";

const urlToSearchState = (location) => qs.parse(location.search.slice(1));
export const CaseContext = React.createContext();

const searchInputPlaceholder =
  "Enter your search terms here (e.g., officer name, incident type...)";
// const searchTooltipPlaceholder = "Enter your search terms here (e.g., officer name, incident type, year of incident, etc.) then click search box"
const searchTooltipPlaceholder = `  <div>
    <span style='display: inline-block;'>Enter your search terms here (e.g., officer name, incident type, year of incident, etc.) then click</span>
    <span style='display: inline-block; color: rgb(29, 209, 89);'>search</span>
    <span style='display: inline-block;'>box</span>
  </div>`;

const AppRouter = () => {
  const location = useLocation();
  const [selectedCase, setSelectedCase] = React.useState(null);
  const [selectedCaseFileData, setSelectedCaseFileData] = React.useState({});
  const [homeSearch, setHomeSearch] = useState("");
  const [searching, setSearching] = useState(false)

  React.useEffect(() => {
    // alert(homeSearch)
  }, [homeSearch]);

  // const [dateState, setDateState] = React.useState([
  //   {
  //     // startDate: new Date('January 1, 1980 01:00:00'),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  function resetDate() {
    // alert('hi')
    setSelectedOne(new Date("January 1, 2000 01:00:00"));
    setSelectedTwo(new Date());
  }

  const [selectedOne, setSelectedOne] = React.useState(
    new Date("January 1, 2000 01:00:00")
  );
  const [selectedTwo, setSelectedTwo] = React.useState(new Date());

  return (
    <CaseContext.Provider
      value={{
        selectedCase,
        setSelectedCase,
        getFiles,
        selectedCaseFileData,
        setSelectedCaseFileData,
        searching
      }}
    >
      <Routes>
        <Route
          element={
            <Layout
              location={location}
              homeSearch={homeSearch}
              setHomeSearch={setHomeSearch}
              selectedOne={selectedOne}
              setSelectedOne={setSelectedOne} // passed to Configure component
              selectedTwo={selectedTwo}
              setSelectedTwo={setSelectedTwo}
              searching={searching}
              setSearching={setSearching}
            />
          }
        >
          <Route path="/" element={<Home />} />
          <Route
            path="/cases"
            element={
              <Cases
                homeSearch={homeSearch}
                setHomeSearch={setHomeSearch}
                selectedOne={selectedOne}
                setSelectedOne={setSelectedOne}
                selectedTwo={selectedTwo}
                setSelectedTwo={setSelectedTwo}
                resetDate={resetDate}
                // searchState={searchState}
                // searchState={'searching'}
              />
            }
          />

          <Route path="/glossary" element={<Glossary />} />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/404" />} />

          {/* <Redirect to="/404" /> */}

          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Route>
      </Routes>
    </CaseContext.Provider>
  );
};

function Layout(props) {
  const { homeSearch, setHomeSearch, selectedOne, selectedTwo, setSearching, searching } = props;
  const location = useLocation();
  let navigate = useNavigate();
  const [searchState, setSearchState] = React.useState(
    urlToSearchState(location)
  );

  useEffect(() => {
    // console.log(searchState.query.length > 0)
    if (searchState?.query?.length > 0)  {
      // alert(JSON.stringify(searchState?.query))
      setSearching(true)
    }
    else {
      setSearching(false)
    }
  }, [searchState])
  

  const setStateId = React.useRef();

  React.useEffect(() => {
    const nextSearchState = urlToSearchState(location);

    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function onSearchStateChange(nextSearchState) {
    if (location.pathname === "/cases") {
      // alert('cases!')
      clearTimeout(setStateId.current);
      setStateId.current = setTimeout(() => {
        // history.push(
        //   searchStateToUrl(location, nextSearchState),
        //   nextSearchState
        // );
        navigate(
          searchStateToUrl(location, nextSearchState),
          nextSearchState
          //  { replace: true }
        );
      }, DEBOUNCE_TIME);

      setSearchState(nextSearchState);
    }
    // console.log(nextSearchState);
  }

  return (
    <div>
      <InstantSearch
        searchClient={searchClient}
        indexName="cases"
        // routing={true}
        // searchState={props.searchState}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
        // createURL={props.createURL}
        // onSearchStateChange={props.onSearchStateChange}
      >
        {/* <Configure
          hitsPerPage={16}
          filters={`INCIDENT_DATE: ${
            dateState[0].startDate === undefined
              ? new Date("January 1, 1980 01:00:00").getTime()
              : Math.floor(dateState[0]?.startDate?.getTime())
          } TO ${Math.floor(dateState[0]?.endDate?.getTime())}`}
        /> */}
        <Configure
          // hitsPerPage={16}
          hitsPerPage={8}
          filters={`INCIDENT_DATE: ${
            selectedOne?.getTime()
            // Math.floor(selectedOne?.getTime())
            // Math.floor(selectedOne?.getTime())
            // dateState[0].startDate === undefined
            //   ? new Date("January 1, 1980 01:00:00").getTime()
            //   : Math.floor(dateState[0]?.startDate?.getTime())
          } TO ${
            selectedTwo?.getTime()
            // Math.floor(selectedTwo?.getTime())
            // Math.floor(dateState[0]?.endDate?.getTime())
          }`}
        />
        <Header
          onSearchStateChange={onSearchStateChange}
          searchState={searchState}
          homeSearch={homeSearch}
          setHomeSearch={setHomeSearch}
        />
        <Outlet homeSearch={homeSearch} setHomeSearch={setHomeSearch} />
      </InstantSearch>
    </div>
  );
}

const Header = ({
  onSearchStateChange,
  searchState,
  homeSearch,
  setHomeSearch,
}) => {
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  let navigate = useNavigate();

  const [headerSearchValue, setHeaderSearchValue] = useState(blank);

  useEffect(() => {
    setUrl(location.pathname);
    if (location.pathname === "/") {
      setHomeSearch("");
    }
  }, [location]);

  const handleHeaderSearch = (event) => {
    let copy = { ...headerSearchValue };
    copy.query = event.target.value;
    setHeaderSearchValue(copy);
    onSearchStateChange(copy);
  };

  const handleHomeSearch = (event) => {
    let copy = { ...headerSearchValue };
    copy.query = event.target.value;
    setHomeSearch(copy);
    // onSearchStateChange(copy);
  };

  let handleSubmit = (event) => {
    // if (url === "/"){
      // if (location.pathname){
      //   alert('home')
      // }
      // alert(location.pathname)
      if (url === "/" || url === "/404"){
        navigate(`/cases?query=${homeSearch}&configure%5BhitsPerPage%5D=16&page=1`);
    }
    
  };

  const handleKeyDown = (evt) => {
    if (["Enter"].includes(evt.key)) {
      // alert('pressed')
      handleSubmit();
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <header>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light"
        style={{
          // borderLeft: "solid black 2px",
          // borderRight: "solid black 2px",
          // boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)",
        }}
      >
        <div
          className="container-fluid"
          style={{ paddingLeft: "18px", marginTop: "-2px" }}
        >
          <>
          <Link
            to="/"
            className="navbar-brand"
            style={{
              // fontFamily: "Lato",
              fontFamily: "Teko",
              fontWeight: "300",
              fontSize: "24px",
              marginRight: "8px",
              border: "solid rgba(100, 100, 100, 0.6) 2px",
              lineHeight: "0.7",
              padding: "4px 20px",
              marginTop: "2px",
              color: "white",
              backgroundColor: "#254457",
            }}
          >
            <b>
              SJ <span style={{ fontSize: "24px" }}>IPA</span>
            </b>
          </Link>
          <button
            // className="navbar-toggler"
            className={`navbar-toggler ${showDropdown ? "" : "collapsed"}`}
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded={showDropdown}
            aria-label="Toggle whether navigation menu is visible or not"
            onClick={() => {
              setShowDropdown((prev) => {
                return !prev;
              });
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          </>
          <div
            // className="collapse navbar-collapse show"
            className={`collapse navbar-collapse ${showDropdown ? "show" : ""}`}
            id="navbarSupportedContent"
            style={{ marginTop: "-1px" }}
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {/* <li className="nav-item">
                <Link
                  to="/"
                  className={`nav-link ${url === "/" ? " link-active" : ""}`}
                  // className="nav-link link-active"
                  aria-current="page"
                  // className="header-items"
                  // style={{ textDecoration: "none", color: "#295F6D" }}
                >
                  Home
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  to="/cases"
                  className={`nav-link ${
                    url === "/cases" ? " link-active" : ""
                  }`}
                  // className="nav-link link-active"
                  aria-current="page"
                  // className="header-items"
                  // style={{ textDecoration: "none", color: "#295F6D" }}
                >
                  Cases
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/glossary"
                  className={`nav-link ${
                    url === "/glossary" ? " link-active" : ""
                  }`}
                  // className="nav-link link-active"
                  aria-current="page"
                  // className="header-items"
                  // style={{ textDecoration: "none", color: "#295F6D" }}
                >
                  Glossary
                </Link>
              </li>
              {/* <li className="nav-item">
                <a
                  // className="nav-link disabled"
                  className="nav-link"
                  href="glossary"
                  tabIndex="-1"
                  aria-disabled="true"
                >
                  Glossary
                </a>
              </li> */}
            </ul>

            <div style={{ display: "none" }}>
              <SearchBox />
            </div>

            <div
              // onSubmit={() => {
              //   handleSubmit();
              // }}
              className="d-flex mr-4"
              style={{
                marginBottom: "-4px",
                minWidth: "60%",
                marginRight: "0.1rem",
              }}
            >
              {(url === "/" || url === "/404") ? (
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder={searchInputPlaceholder}
                  aria-label="Search"
                  onKeyDown={handleKeyDown}
                  // onChange={handleHeaderSearch}
                  value={homeSearch}
                  onChange={(e) => setHomeSearch(e.target.value)}
                  data-tip={searchTooltipPlaceholder}
                  style={{ fontFamily: "Open Sans" }}
                  // value={homeSearch.query}
                  // onChange={(e) => handleHomeSearch(e.target.value)}
                />
              ) : (
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder={searchInputPlaceholder}
                  aria-label="Search"
                  onChange={handleHeaderSearch}
                  value={searchState.query}
                  data-tip={searchTooltipPlaceholder}
                  style={{ fontFamily: "Open Sans" }}
                />
              )}
              <ReactTooltip
                // place="left"
                type="dark"
                className="tooltip-search"
                html={true}
              />
              {/* <Link to="/cases"> */}
              <button
                className="btn btn-outline-success"
                type="button"
                onClick={handleSubmit}
              >
                Search
              </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default AppRouter;
