import { min } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
// import PageNotFound from '../assets/images/PageNotFound';
class NotFoundPage extends React.Component {
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          padding: "6rem 20px 40px 20px",
          width: "min(900px, 100%)",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <br />
        <h1 className="h1 text-center mt-3">Error 404</h1>

        <div className="landing-body" style={{ marginBottom: "28px" }}>
          <p>
            It looks like you've reached a URL that doesn't exist. Please use
            the links below or the navigation bar above to reach other sections
            of the site.
          </p>
        </div>
        {/* <img src={PageNotFound}  /> */}
        <p style={{ textAlign: "center" }}>
          <Link to="/">Go to Home </Link>
        </p>
        <p style={{ textAlign: "center" }}>
          <Link to="/cases">View Cases </Link>
        </p>
      </div>
    );
  }
}
export default NotFoundPage;
