import React, { useState } from "react";
import {
  InstantSearch,
  Hits,
  RefinementList,
  SearchBox,
  Stats,
  Pagination,
  Panel,
  ClearRefinements,
  Highlight,
  Configure,
  connectStateResults,
} from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";

import "./Cases.scss";
import "./algolia-min.scss";
import "./styles/pill-styles.css";

import { useNavigate } from "react-router-dom";
import { CaseContext } from "./AppRouter";

// import "react-date-range/dist/styles.css"; // main style file
// import "react-date-range/dist/theme/default.css"; // theme css file
// import { DateRange } from "react-date-range";

import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

const searchClient = algoliasearch(
  "Y4H4S754AV",
  "3a6f2fcaf2b5013274b1aa887e7785e4"
);

const openUrl = (url) => {
  window.open(url, "_blank");
};

const Cases = (props) => {
  const navigate = useNavigate();

  React.useEffect(() => {}, []);

  const {
    selectedCase,
    setSelectedCase,
    getFiles,
    selectedCaseFileData,
    setSelectedCaseFileData,
  } = React.useContext(CaseContext);

  const [advancedSearch, setAdvancedSearch] = React.useState(true);

  React.useEffect(() => {
    if (selectedCase !== null) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [selectedCase]);

  const escFunction = (event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      // alert('pressed ESC!')
      setSelectedCase(null);
      setSelectedCaseFileData({});
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div
      className="cases-wrapper"
      style={{ overflow: "hidden", maxWidth: "100vw" }}
    >
      <div
        id="myNav"
        className="overlay"
        style={{
          zIndex: 2,
          width: selectedCase === null ? "0%" : "100%",
          opacity: selectedCase === null ? "0" : "100%",
        }}
      >
        <a
          style={{ cursor: "pointer" }}
          className="closebtn"
          onClick={() => {
            setSelectedCase(null);
            setSelectedCaseFileData({});
          }}
        >
          &times;
        </a>

        <div className="overlay-content">
          <div>
            <p
              className={selectedCase === null ? "preventWrap" : ""}
              // className="preventWrap"
              style={{
                color: "white",
                fontSize: "26px",
                paddingBottom: "16px",
              }}
            ></p>
            <div
              style={{
                color: "white",
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <p className={selectedCase === null ? "preventWrap" : ""}>
                <b>
                  Incident Type
                  {selectedCase?.INCIDENT_TYPES.length > 1 ? "s" : null}:{" "}
                </b>
                <div style={{ border: "solid black 2px", height: "5px" }} />
                <div className={selectedCase === null ? "preventWrap" : ""}>
                  {selectedCase?.INCIDENT_TYPES.map((type, index) => (
                    <>
                      <div>
                        <div
                          style={{ marginLeft: "auto", marginRight: "auto" }}
                        >
                          {/* <ul> */}

                          <span>
                            Sustained Finding of <b>{type}</b>
                          </span>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </p>
            </div>

            <div style={{ color: "white" }}>
              <p className={selectedCase === null ? "preventWrap" : ""}>
                <b>Incident Date: </b>
                {new Date(selectedCase?.INCIDENT_DATE).toDateString()}
              </p>
            </div>
          </div>
          <div>
            <p
              className={selectedCase === null ? "preventWrap" : ""}
              style={{
                color: "white",
                marginRight: "10px",
                display: "inline-block",
                marginBottom: "4px",
              }}
            >
              <b>Associated Officers: </b>
            </p>
            <div
              style={{
                margin: "3px",
                maxHeight: "60px",
                overflowY: "scroll",
                border: "solid black",
                // width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                minHeight: "55px",
                minWidth: "40vw",
              }}
              className={selectedCase === null ? "preventWrap" : ""}
            >
              {selectedCase?.ASSOCIATED_OFFICERS_LIST.map((item) => (
                <div className="tag-item" key={item} style={{}}>
                  {item.officer}, {item.badge}
                </div>
              ))}
            </div>
          </div>
          <br />
          <div style={{ color: "white" }}>
            {Object.keys(selectedCaseFileData).map((keyName, i) => (
              <>
                {/* {keyName} */}
                <div style={{ marginBottom: "10px", marginTop: "-15px" }}>
                  To access redacted <b>IPA documents</b> click on the box below
                </div>

                <div
                  style={{
                    color: "white",
                    border: "solid white",
                    paddingTop: "8px",
                    width: "60%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: selectedCase === null ? "0%" : "60%",
                    minHeight: selectedCase === null ? "0px" : "200px",
                    transition: "0.5s",
                  }}
                >
                  {selectedCaseFileData[keyName] !== {} ? (
                    <ul style={{ listStyle: "none", border: "solid grey 1px" }}>
                      {/* {selectedCaseFileData[keyName].map(()=>(<></>)} */}
                      {selectedCaseFileData[keyName].map((item) => (
                        <li
                          style={{
                            cursor: "pointer",
                            paddingBottom: "6px",
                            fontSize: "16px",
                          }}
                          onClick={() => {
                            openUrl(item.url);
                          }}
                        >
                          {item.docName}
                        </li>
                      ))}
                    </ul>
                  ) : null}

                  <br />
                  <br />
                  <br />
                </div>
                <br />
              </>
            ))}
          </div>
        </div>
      </div>

      <div
        style={{
          padding: "30px 20px 40px 20px",
        }}
      >
        <h1 className="page-header text-center mt-3" 
        // style={{ color: "white" }}
        >
          Cases
        </h1>

        <div
          className="row content-wrapper g-0"
          style={{
            padding: "0px",
            width: "96%",
          }}
        >
          <div
            className="col-12 col-md-6 col-lg-5 col-xl-4"
            style={{
              borderRadius: "5px",
              marginRight: "-20px !important",
              marginBottom: "6px",
            }}
          >
            <button
              className="btn btn-dark"
              // className="btn btn-light"
              style={{
                transform: "scale(0.9)",
                width: "230px",
                whiteSpace: "nowrap",
                marginLeft: "-9px"
              }}
              onClick={() => {
                setAdvancedSearch(!advancedSearch);
              }}
            >
              <div>
                <Arrow advancedSearch={advancedSearch}/>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
              {!advancedSearch ? "Show" : "Hide"} Advanced Search
            </button>
          </div>
        </div>

        {/* <br/> <br/> */}
        <div className="row content-wrapper">
          <div className="col-12 col-md-6 col-lg-5 col-xl-4">
            {advancedSearch && (
              <Facets
                resetDate={props.resetDate}
                selectedOne={props.selectedOne}
                setSelectedOne={props.setSelectedOne}
                selectedTwo={props.selectedTwo}
                setSelectedTwo={props.setSelectedTwo}
              />
            )}
          </div>

          <div
            style={{ marginTop: "15px" }}
            className={`col-12 ${
              advancedSearch ? "col-md-6 col-lg-7 col-xl-8" : "col-md-12"
            } `}
          >
            <CustomResults />
          </div>
        </div>
      </div>
    </div>
  );
};

const Facets = (props) => {
  function showDatePicker(n) {
    if (n === 1) {
      setDisplayPickerOne((prev) => !prev);
      setDisplayPickerTwo(false);
    }
    if (n === 2) {
      setDisplayPickerTwo((prev) => !prev);
      setDisplayPickerOne(false);
    }
  }

  const [displayPickerOne, setDisplayPickerOne] = useState(false);
  const [displayPickerTwo, setDisplayPickerTwo] = useState(false);

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function formatDate(input) {
    var date = new Date(input);
    return [
      months[date.getMonth()], // month
      `${date.getDate()},`, // day
      date.getFullYear(), // year
    ].join(" ");
  }

  return (
    <aside
      aria-label="Advanced search settings panel"
      className="cases-advanced-settings"
      style={{
        // backgroundColor: "rgba(0,0,0,.1)",
        // backgroundColor: "rgba(0,0,0,.1)",

        // borderTop:'solid black 2px',
        // borderBottom:'solid black 2px',
        // borderLeft:'solid black 2px',
        // borderRight:'solid black 2px',

        marginTop: "2px",
        marginBottom: "20px",
      }}
    >
      <div className="top-card" style={{ marginTop:'-3px', padding:'8px', margin: '2px'}}>
      <div
      
      onClick={() => {
        // setDateState([{key: "selection"}])
        // alert('done')
      }}
    >
      <div
        style={{
          paddingLeft: "4px",
          paddingTop: "8px",
          paddingBottom: "8px",
          borderTop: "solid black 1px",
          borderBottom: "solid black 1px",
        }}
      >
        <h1
          style={{
            float: "left",
            paddingLeft: "8px",
            marginRight: "auto",
            fontSize: "19px",
            // marginTop: "-1px",
            fontWeight:"initial",
            // fontFamily: "Open Sans"
            
          }}
        >
          Filter by:
        </h1>
        <div
          aria-label="Reset selections of incident type for adanced search"
          style={{
            marginLeft: "auto",
            display: "block",
            width: "5.6rem",
            marginRight: "15px",
          }}
        >
          <ClearRefinements
            translations={{
              reset: "Reset filters",
            }}
          />
        </div>
      </div>
    </div>

    <div className="filter-panel-options">
      <div aria-a className="event-types-section">
        <Panel header="Cases Involving">
          <div >
            <RefinementList
              attribute="INCIDENT_TYPES"
              operator="or"
              limit={10}
            />
          </div>
        </Panel>
      </div>
      <div
        style={{ textAlign: "center", marginTop: "-10px" }}
        aria-label="Date"
        className="date-section"
      >
        <Panel header="Date">
          <div
            className="sub"
            style={{
              marginBottom: "-10px",
              // backgroundColor:'red'
            }}
          >
            {/* <div style={{width:'30%', height:'1px', backgroundColor:'black', margin:'auto'}}/> */}

            <div aria-label="Select start date for advanced search"
              style={{
                // border: "solid black 1px",
                borderRadius: "3px",
                padding: "5px",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  marginBottom: "2px",
                  marginTop: "-2px",
                  fontSize:'1rem',
                  display:'block'
                }}
              >
                From
              </span>
              <button
                className="btn btn-dark"
                style={{
                  marginRight: "8px",
                  transform: "scale(0.9)",
                  marginTop: "-2px",
                }}
                onClick={() => {
                  showDatePicker(1);
                }}
              >
                Select Start date
              </button>
              <div
                style={{
                  display: "inline-block",
                  border: "solid 1px rgba(0,0,0,0.3)",
                  padding: "2px",
                  fontFamily: "Open Sans",
                }}
              >
                {formatDate(props.selectedOne)}
              </div>
            </div>

            <div style={{width:'30%', height:'1px', backgroundColor:'black', margin:'auto'}}/>

            {/* <div style={{width:'100%', height:'1px', backgroundColor:'black'}}/> */}
            
            <div aria-label="Select end date for advanced search"
              style={{
                // borderTop: "solid black 1px",
                // border: "solid black 1px",
                borderRadius: "3px",
                padding: "5px",
                borderTop: "0px",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  marginBottom: "2px",
                  fontSize:'1rem',
                  display:'block'
                }}
              >
                To
              </span>
              <button
                className="btn btn-dark"
                style={{
                  width: "152px",
                  marginRight: "8px",
                  transform: "scale(0.9)",
                  marginTop: "-2px",
                }}
                onClick={() => {
                  showDatePicker(2);
                }}
              >
                Select End date
              </button>
              <div
                style={{
                  display: "inline-block",
                  border: "solid 1px rgba(0,0,0,0.3)",
                  padding: "2px",
                  fontFamily: "Open Sans",
                }}
              >
                {formatDate(props.selectedTwo)}
              </div>
            </div>
            {/* <div style={{width:'30%', height:'1px', backgroundColor:'black', margin:'auto'}}/> */}

            {/* <DayPicker/> */}
            <div style={{ marginTop: "10px" }}>
              {displayPickerOne ? (
                <div style={{ border: "solid black 1px" }}>
                  <DayPicker
                    mode="single"
                    selected={props.selectedOne}
                    onSelect={(s) => {
                      props.setSelectedOne(s);
                      setDisplayPickerOne(false);
                    }}
                    footer={"Start Date"}
                    fromYear={2000}
                    toYear={new Date().getFullYear()}
                    captionLayout="dropdown"
                    defaultMonth={new Date(2000, 0)}
                  />
                </div>
              ) : (
                ""
              )}
              {displayPickerTwo ? (
                <div style={{ border: "solid black 1px" }}>
                  <DayPicker
                    mode="single"
                    selected={props.selectedTwo}
                    onSelect={(s) => {
                      props.setSelectedTwo(s);
                      setDisplayPickerTwo(false);
                    }}
                    footer={"End Date"}
                    fromYear={2000}
                    toYear={new Date().getFullYear()}
                    captionLayout="dropdown"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
          aria-label="Reset date selections for adanced search to default options"
            style={{ border: "solid black 1px", marginTop: "15px" }}
            className="btn btn-light"
            onClick={props.resetDate}
          >
            Reset Dates
          </div>
        </Panel>
      </div>
    </div>
    <div style={{ marginTop: "14px" }}>
      <Stats />
    </div>
      </div>
    </aside>
  );
};

const Hit = ({ hit }) => {
  let date = new Date(hit.INCIDENT_DATE).toDateString();

  const {
    selectedCase,
    setSelectedCase,
    getFiles,
    selectedCaseFileData,
    setSelectedCaseFileData,
    searching,
  } = React.useContext(CaseContext);

  return (
    <div className="hit" 
    style={{ marginTop:'1.4rem'}}

    // style={{ marginTop: searching ? '1.4rem' : '1.4rem'}}
    >
      <article
        
        style={{
          cursor: "pointer",
          padding: "10px",
          backgroundColor:'white',
        }}
        onClick={() => {
          setSelectedCase(hit);
          getFiles(hit, selectedCaseFileData, setSelectedCaseFileData);
        }}
      >
        <h1 aria-label="Case number assigned to this incident"
          className="results-main-text"
        >
          Case: <Highlight attribute="CASE_NUMBER" hit={hit} />
        </h1>
        <h2 aria-label="Date in which this incident took place"
          style={{ padding: "0px 5px", fontSize:'1rem' }}
        >
          Incident Date: {date}
        </h2>
        <div
          aria-label="List of incident types which describe this case"
          className="result-incident-types"
        >
          Incident Types: <Highlight attribute="INCIDENT_TYPES" hit={hit} />
          {hit.INCIDENT_TYPES.length == 0 ? (
            <span style={{ textDecoration: "underline" }}>N/A</span>
          ) : null}
        </div>
        <div
          aria-label="List of officers involved with this incident, and their respective badge numbers"
          className="officers-info"
          style={{
            padding: "10px",
            // height: "50px",
            height: !searching ? "46px" : "65px",
            transition: "all 0.5s",

            overflowY: "scroll",
            border: "solid rgba(0,0,0,0.5)",
          }}
        >
          <div style={{ textAlign: "center", marginTop: "-6px" }}>
            <div
              style={{ marginBottom: "4px", fontSize: "1.05rem" }}
              className="results-main-text"
            >
              Associated Officers
            </div>
            {/* <br /> */}
            {hit.ASSOCIATED_OFFICERS_LIST.map((item) => (
              <div className="tag-item" key={Math.random().toString(36)}>
                {item.officer}, #{item.badge}
              </div>
            ))}
            {hit.ASSOCIATED_OFFICERS_LIST.length == 0 ? (
              <div style={{ textDecoration: "underline" }}>N/A</div>
            ) : null}
          </div>
        </div>
      </article>
    </div>
  );
};


const CustomResults = connectStateResults(({ searchState, searchResults }) => {
  return (
    <div>
      {searchResults && searchResults.nbHits ? (
        <div>
          <div
           style={{marginTop: '-2.2rem'}}
           >
            <Hits hitComponent={Hit} searchState={searchState}/>
          </div>
          <footer>
            <Pagination showLast={true} />
          </footer>
        </div>
      ) : (
        <div className="no-results">
          No results found matching &quot;
          <span className="query">{searchState.query}</span>
          &quot;
        </div>
      )}
    </div>
  );
});

function Arrow(props){
  return (
    <div class="icon">
      <div class={`arrow ${props.advancedSearch ? 'rotated' : ''}`}></div>
    </div>
  );
}

export default Cases;
