import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import "./styles/list-groups.css";
// import "./styles/scss/main.scss"
// import "./styles/main.scss";

function newTab(e, url) {
  e.preventDefault();
  window.open(url, "_blank");
}

const Hero = () => {
  return (
    <section>
      <div
        className="row justify-content-center g-0"
        style={{ width: "100%", overflow: "hidden" }}
      >
        <div className="col-lg-7 col-sm-12 mt-1">
          <>
            <div className="hero-header">
              <div className="h4 mb-4 mt-4" style={{ lineHeight: "1.3" }}>
                <h1
                className="h1"
                  style={{
                    marginBottom: "-5px",
                  }}
                >
                  Senate Bill 1421 Public Records Portal
                </h1>
              </div>
              <div className="landing-body" style={{ marginBottom: "28px" }}>
                <p>
                  The San José Independent Police Auditor is committed to
                  providing transparency to our residents and the general
                  public. In accordance with the California Public Records Act
                  and Senate Bill 1421, we have constructed this portal with the
                  assistance of the Mayor's Office of Technology and Innovation
                  to store officer misconduct records.
                </p>
                <p>
                  This site can be used to view and download redacted records
                  made available under:
                </p>
                <p>
                  Senate Bill 1421 pertaining to incidents of officer involved
                  shootings and the use of force resulting in great bodily
                  injury, as well as sustained findings of officer dishonesty
                  and sexual assault.
                </p>
                <p>
                  Senate Bill 16 pertaining to sustained findings of excessive
                  force, the failure to intervene in an excessive force
                  incident, unlawful search and/or prejudice or discrimination.
                </p>
                <p>
                  Access records on this site will be expanded if California law
                  expands availability.
                </p>
                <p>
                  The records are text and image based, available in PDF. The
                  site currently does not include video and audio format
                  records.
                </p>
                {/* <br /> */}
              </div>
              <Link to="/cases">
                <button className="view-records-button">
                  View all redacted public records
                </button>
              </Link>
            </div>
          </>
        </div>

        <div className="col-lg-5 col-sm-12 img-wrapper">
          <div className="img" />
        </div>
      </div>
    </section>
  );
};

const Home = () => {
  return (
    <>
      <div
        style={{
          borderLeft: "solid black 2px",
          borderRight: "solid black 2px",
        }}
      >
        <Hero />

        <section
          className="p-5 pt-4"
          style={{
            border: "solid black 1px",
            borderRadius: "2px",
            color: "white",
            backgroundColor: "#1B1F21", // Shark
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.4)",
            position: "relative",
          }}
        >
          <h1
            className="h1 mb-4 text-center mt-4"
            style={{ fontWeight: "700", fontSize: "28pt", color: "white" }}
          >
            FAQ
          </h1>
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-4">
              <h2 className="h2" style={{ color: "white", marginBottom:'20px' }}>Who created this site?</h2>
              {/* <br /> */}
              <div className="body" style={{ fontSize: "12pt" }}>
                <p style={{ color: "#C7D0D9" }} //Ghost
                >
                  This first version of the site was created by a team of five
                  Mayor's Office of Technology and Innovation fellows in
                  partnership with CodeX - The Stanford Center for Legal
                  Informatics & the Independent Police Auditor in the summer of
                  2021. The Deputy Chief Innovation Officer served as project
                  manager until the tool's launch in 2022.
                </p>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-4 mb-4"
              style={{ marginLeft: "7px", marginRight: "-7px" }}
            >
              <h2 className="h2" style={{ color: "white", marginBottom:'20px' }}>Who is this site for?</h2>
              <div className="body" style={{ fontSize: "12pt" }}>
                <p
                  style={{ color: "#C7D0D9" }} //Ghost
                >
                  The general public of San José, members of the media, members
                  of the criminal justice system, and potential employers of
                  former SJPD officers.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 mb-4">
              <h2 className="h2" style={{ color: "white", marginBottom:'20px' }}>
                What Records are available on this site?
              </h2>
              <div className="body" style={{ fontSize: "12pt" }}>
                <p
                  style={{ color: "#C7D0D9" }} //Ghost
                >
                  The IPA is not the custodian of all records of an officer
                  misconduct case. The IPA is solely the custodian of records
                  created in the IPA office. SJPD is the custodian of additional
                  records for the case such as Body-worn Camera (BWC) footage,
                  police reports, computer-aided dispatch (CAD) records,
                  photographs, etc. To obtain these records, please click&nbsp;
                  <a
                    href="https://pd-sanjoseca.govqa.us/WEBAPP/_rs/(S(1l44yyp2vw3xww4k0lfsc23l))/openrecordssummary.aspx?sSessionID=9414011129IXMGWXQONOKKOPJHQJVDURSQYMJRMB&view=10"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={(e) => {
                      newTab(
                        e,
                        "https://pd-sanjoseca.govqa.us/WEBAPP/_rs/(S(1l44yyp2vw3xww4k0lfsc23l))/openrecordssummary.aspx?sSessionID=9414011129IXMGWXQONOKKOPJHQJVDURSQYMJRMB&view=10"
                      );
                    }}
                  >
                    here
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-5">
          <div className="shadow list-group col-10 col-sm-11 col-md-9 col-lg-9 resources-wrapper">
            <a
              // href="#"
              href="/https://www.sanjoseca.gov/your-government/appointees/independent-police-auditor/complaint-form"
              onClick={(e) => {
                newTab(
                  e,
                  "https://www.sanjoseca.gov/your-government/appointees/independent-police-auditor/complaint-form"
                );
              }}
              style={{ cursor: "pointer" }}
              className="list-group-item list-group-item-action d-flex gap-3 py-3"
              aria-current="true"
            >
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <div style={{ marginLeft: "-4px" }}>
                    <i
                      className="bi bi-paperclip resources-icons"
                      style={{
                        display: "inline-block",
                        fontSize: "24px",
                        marginTop: "-8px",
                        marginRight: "3px",
                        marginBottom: "-5px",
                      }}
                    />
                    <h3
                      className="h3 mb-0 resources-text"
                      style={{ display: "inline-block", fontSize: "21pt" }}
                    >
                      File a complaint
                    </h3>
                  </div>
                  <p className="mb-0 opacity-75 resources-text-link">
                    https://www.sanjoseca.gov/your-government/appointees/independent-police-auditor/complaint-form
                  </p>
                </div>
              </div>
            </a>
            <a
              href="/https://www.sanjoseca.gov/your-government/appointees/independent-police-auditor/ipa-recommendations"
              onClick={(e) => {
                newTab(
                  e,
                  "https://www.sanjoseca.gov/your-government/appointees/independent-police-auditor/ipa-recommendations"
                );
              }}
              style={{ cursor: "pointer" }}
              className="list-group-item list-group-item-action d-flex gap-3 py-3"
              aria-current="true"
            >
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <div>
                    <i className="bi bi-chat-left-text-fill resources-icons" />
                    <h3
                      className="h3  mb-0 resources-text"
                      style={{ display: "inline-block", fontSize: "21pt" }}
                    >
                      IPA Recommendations
                    </h3>
                  </div>
                  <p className="mb-0 opacity-75 resources-text-link">
                    https://www.sanjoseca.gov/your-government/appointees/independent-police-auditor/ipa-recommendations
                  </p>
                </div>
              </div>
            </a>
            <a
              // href="#"
              href="/https://www.sanjoseca.gov/your-government/appointees/independent-police-auditor/publications"
              onClick={(e) => {
                newTab(
                  e,
                  "https://www.sanjoseca.gov/your-government/appointees/independent-police-auditor/publications"
                );
              }}
              style={{ cursor: "pointer" }}
              className="list-group-item list-group-item-action d-flex gap-3 py-3"
              aria-current="true"
            >
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <div>
                    <i className="bi bi-subtract resources-icons" />
                    <h3
                      className="h3 mb-0 resources-text"
                      style={{ display: "inline-block", fontSize: "21pt" }}
                    >
                      {/* 2022 IPA Report */}
                      {/* View all published IPA reports */}
                      Latest IPA Reports and Publications
                    </h3>
                  </div>
                  <p
                    className="mb-0 opacity-75 resources-text-link"
                    style={{
                      whiteSpace: "normal",
                      wordBreak: "break-all",
                    }}
                  >
                    https://www.sanjoseca.gov/your-government/appointees/independent-police-auditor/publications
                  </p>
                </div>
              </div>
            </a>
            <a
              // href="#"
              href="/https://www.sanjoseca.gov/your-government/appointees/independent-police-auditor/establishment-of-the-office"
              onClick={(e) => {
                newTab(
                  e,
                  "https://www.sanjoseca.gov/your-government/appointees/independent-police-auditor/establishment-of-the-office"
                );
              }}
              style={{ cursor: "pointer" }}
              className="list-group-item list-group-item-action d-flex gap-3 py-3"
              aria-current="true"
            >
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <div>
                    <i className="bi bi-info-circle-fill resources-icons" />
                    <h3
                      className="h3 mb-0 resources-text"
                      style={{ display: "inline-block", fontSize: "21pt" }}
                    >
                      About the IPA
                    </h3>
                  </div>
                  <p className="mb-0 opacity-75 resources-text-link">
                    https://www.sanjoseca.gov/your-government/appointees/independent-police-auditor/establishment-of-the-office
                  </p>
                </div>
              </div>
            </a>
          </div>
        </section>


        <Footer/>
        <br />
      </div>
    </>
  );
};

function Footer(){
  return (
    <footer className="footer">
    <div className="container text-center">
      <span style={{ fontFamily: "Open Sans", fontSize: "12pt" }}>
        San José - IPA
      </span>
      <br />
      <i
        aria-label="Link to the SJ IPA Twitter page"
        onClick={(e) => {
          newTab(e, "https://twitter.com/SanJoseIPA");
        }}
        className="bi bi-twitter social-icons"
      />
      <i
        aria-label="Link to the SJ IPA Facebook page"
        onClick={(e) => {
          newTab(e, "https://www.facebook.com/SanJoseIPA");
        }}
        className="bi bi-facebook social-icons"
      />
      <i
      aria-label="Link to the SJ IPA Instagram page"
        onClick={(e) => {
          newTab(e, "https://www.instagram.com/sanjoseipa");
        }}
        className="bi bi-instagram social-icons"
      />
    </div>
  </footer>
  )
}
export default Home;
