import React, { useState, createContext } from "react";

const GlobalContext = createContext()
const { Provider, Consumer} = GlobalContext; 

export const GlobalContextWrapper = (props) => {
  const [selectedCase, setSelectedCase] = useState()
  return (
    <div style={{height:'300px'}}>
    {/* <Provider value={{selectedCase, setSelectedCase}}> */}
      {props.childern}
    {/* </Provider> */}
    </div>
  );
};

// export { GlobalContextWrapper, Consumer as GlobalContextConsumer, GlobalContext }
