import { ref, getDownloadURL, listAll } from "firebase/storage";
import { firebaseStorage } from "./index";

export const getFiles = (
  props,
  selectedCaseFileData,
  setSelectedCaseFileData
) => {
  const listRef = ref(firebaseStorage, `cases/${props.CASE_NUMBER}/`);

  listAll(listRef)
    .then((res) => {
      res.prefixes.forEach((folderRef) => {
        let category = folderRef.toString().slice(55);

        listAll(folderRef)
          .then((res2) => {
            res2.items.forEach((res3) => {
              // All the items under listRef.
              let docName = res3
                .toString()
                .substring(
                  res3.toString().lastIndexOf("/") + 1,
                  res3.toString().length
                );
                // console.log('docName: ', docName)

              getDownloadURL(ref(firebaseStorage, res3))
                .then((url) => {
                  try {
                    let fileObj = {
                      category: category,
                      docName: docName,
                      url: url,
                    };
                    handleNewFile(
                      fileObj,
                      selectedCaseFileData,
                      setSelectedCaseFileData
                    );
                    // console.log('fileObj:', fileObj)
                  } catch (err) {
                    // console.log(err)
                  }
                  // This can be downloaded directly:
                })
                .catch((error) => {
                  // Handle any errors
                });
            });
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
          });
      });
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
};

const handleNewFile = (file, selectedCaseFileData, setSelectedCaseFileData) => {


    setSelectedCaseFileData((prevState) => {
        if ( prevState[file.category] === undefined ){
            prevState[file.category] = [file]
        }
        else if ( prevState[file.category] !== undefined ){
            prevState[file.category].push(file)
        }
        return({
          ...prevState
        })
      }
    )
    

    // let filesCopy = JSON.parse(JSON.stringify(selectedCaseFileData));
    // let filesCopy = {...selectedCaseFileData}

    // if (file.category in filesCopy) {
    //   alert("exists!");
    // } 
    // else if (!(file.category in filesCopy)) {
    //   alert("doesnt exist!");
    //   filesCopy[file.category] = [file]
    // }

    // console.log('filesCopy', filesCopy)
    // filesCopy[file.category].push(file);
    
//   var copyFileData = JSON.parse(JSON.stringify(selectedCaseFileData));

//   if (file.category in copyFileData) {
//     let arrOfCategoryModified = [...copyFileData[file.category], file];
//     copyFileData[file.category] = arrOfCategoryModified;
//   }

//   setSelectedCaseFileData(filesCopy);
};
